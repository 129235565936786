import _objectSpread from "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'SearchComponent',
  data: function data() {
    return {
      value: '',
      show: false,
      reload: false
    };
  },
  computed: _objectSpread({}, mapGetters('router', ['routerList'])),
  methods: {
    changeRouter: function changeRouter() {
      this.$router.push({
        name: this.value
      });
      this.value = '';
    },
    hiddenSearch: function hiddenSearch() {
      this.show = false;
    },
    showSearch: function showSearch() {
      var _this = this;

      this.show = true;
      this.$nextTick(function () {
        _this.$refs['search-input'].focus();
      });
    },
    handleReload: function handleReload() {
      var _this2 = this;

      this.reload = true;
      this.$bus.$emit('reload');
      setTimeout(function () {
        _this2.reload = false;
      }, 500);
    }
  }
};